// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./AuthWaves.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".authsidebox_K1GYa {\n  color: #ffffff;\n  border-radius: 16px;\n  width: 246px;\n  background: #aacbf2 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.authsidebox_K1GYa .title_iI8YP {\n  font-family: \"Raleway\", serif;\n  line-height: 18.78px;\n  font-size: 16px;\n  font-weight: 800;\n  letter-spacing: 0.03em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SideBox/style.m.less"],"names":[],"mappings":"AAAA;EAGE,cAAA;EACA,mBAAA;EACA,YAAA;EACA,2DAAA;AADF;AALA;EASM,6BAAA;EACA,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;AADN","sourcesContent":[".authsidebox {\n  @import 'style/palette';\n  \n  color: @gray-1;\n  border-radius: 16px;\n  width: 246px;\n  background: @sidebar url('./AuthWaves.svg');\n\n  .title {\n      font-family: \"Raleway\", serif;\n      line-height: 18.78px;\n      font-size: 16px;\n      font-weight: 800;\n      letter-spacing: 0.03em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authsidebox": "authsidebox_K1GYa",
	"title": "title_iI8YP"
};
export default ___CSS_LOADER_EXPORT___;
