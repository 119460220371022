import { LabelRow, Outline, Price, Row, Typography, Wallet } from '@dltru/dfa-ui'
import cn from 'classnames'
import { FC } from 'react'

import style from './walletWidget.m.less'

interface IWalletWidgetProps {
    balance?: number
    account?: string
    error: boolean
}

export const WalletWidget: FC<IWalletWidgetProps> = ({ balance, account, error }) => {
    return (
        <Outline className={cn(style.walletWindget, { [`${style.walletWindgetError}`]: error })}>
            <Row justify="space-between">
                <Typography.Text type="secondary">Счёт</Typography.Text>
                {error && (
                    <Typography.Text type="danger">Недостаточно средств на счёте</Typography.Text>
                )}
            </Row>
            <LabelRow
                label={
                    <>
                        <Wallet />
                        <span>{String(account)}</span>
                    </>
                }
                strong
            >
                <Price price={balance && balance / 100} error={error} />
            </LabelRow>
        </Outline>
    )
}
