import {
    Box,
    CardAdd,
    Case,
    DocumentText,
    Exchange,
    Layout,
    MasterchainLogo,
    Menu,
} from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'

import { MyBalanceWidget } from '../MyBalanceWidget'
import { SidebarLink } from './components/SidebarLink'
import style from './style.m.less'

const { Sider } = Layout
const { Item } = Menu

export const Sidebar: FC = () => {
    const userUID = useSelector(authSelector.selectUserUid)
    return (
        <Sider className={style.sider}>
            <Link to="/offers">
                <Box direction="row" padding={[20, 24]} margin={[8, 0, 20, 0]}>
                    <div className={style.siderlogo}>
                        <MasterchainLogo />
                    </div>
                    <div className={style.siderlogoname}>
                        <span>Цифровые финансовые активы</span>
                    </div>
                </Box>
            </Link>
            <Menu mode="inline" className="">
                <Item key="offers" icon={<Exchange />}>
                    <SidebarLink to="/offers">Витрина предложений</SidebarLink>
                </Item>
                <Menu.Divider style={{ margin: '16px 12px' }} />
                <Item key="emission" icon={<CardAdd />}>
                    <SidebarLink to="/emissions">Выпуски ЦФА</SidebarLink>
                </Item>
                <Item key="applications" icon={<DocumentText />}>
                    <SidebarLink to={`/clients/${userUID}`} locationState={{ tabKey: 'orders' }}>
                        Мои заявки
                    </SidebarLink>
                </Item>
                <Item key="actives" icon={<Case />}>
                    <SidebarLink to={`/clients/${userUID}`} locationState={{ tabKey: 'wallet' }}>
                        Мои активы
                    </SidebarLink>
                </Item>
            </Menu>
            <MyBalanceWidget />
        </Sider>
    )
}
