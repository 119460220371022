// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".walletWindget_lQm5i {\n  margin: 18px 0;\n}\n.walletWindgetError_kbL0F {\n  background: #fff1f0;\n  border-color: #ff7875;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WalletWidget/walletWidget.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;AADJ;AAGI;EACI,mBAAA;EACA,qBAAA;AADR","sourcesContent":["@import '/src/assets/style/palette';\n\n.walletWindget {\n    margin: 18px 0;\n\n    &Error {\n        background: @red-1;\n        border-color: @red-4;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletWindget": "walletWindget_lQm5i",
	"walletWindgetError": "walletWindgetError_kbL0F"
};
export default ___CSS_LOADER_EXPORT___;
