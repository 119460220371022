import { CertificateType, UserCertificateType } from '@dltru/dfa-models'
import { SignUtils } from '@dltru/dfa-sign'
import { Alert, UserCertificateItem } from '@dltru/dfa-ui'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { certificateSlice } from '@store/certificates'
import { certificateSelectors } from '@store/certificates/selectors'
import TransactionDetails from '@components/Modals/Certificate/TransactionDetails'
import dayjs from 'dayjs'

interface IComponentProps {
    selectedCertificate?: CertificateType
    setSelectedCertificate: (value?: CertificateType) => void
    showTransactionDetails?: boolean
}

export const UserCertificateList: FC<IComponentProps> = ({
    selectedCertificate,
    setSelectedCertificate,
    showTransactionDetails,
}) => {
    const [certificates, setCertificates] = useState<CertificateType[] | null>(null)
    const reduxDispatch = useDispatch()
    const certificateFromStore = useSelector(certificateSelectors.selectItems)
    const getCertificate = async () => {
        const allCertificates: UserCertificateType[] = await SignUtils.getAllCertificates()
        const userCertificates = certificateFromStore?.filter(({ sha1 }) => {
            const findedCertificate = allCertificates?.find(
                ({ thumbprint }) => thumbprint.toLowerCase() === sha1.toLowerCase(),
            )
            return findedCertificate?.checkPrivateKey
        })
        
        setCertificates(userCertificates ?? [])
    }

    useLayoutEffect(() => {
        if (certificateFromStore?.length === 0) {
            setCertificates([])
        }
        if (certificateFromStore === null) {
            reduxDispatch(certificateSlice.actions.getItems())
        }

        if (certificateFromStore?.length) {
            getCertificate()
        }
    }, [certificateFromStore])

    useEffect(() => {
        if (certificates?.length === 1) {
            const certificate = certificates[0]
            const isExpired = certificate.expired_at && dayjs().isAfter(dayjs(certificate.expired_at))
            const isWarning = false // TODO нет критериев проверки использования на платформе
            const isNotValid = false // TODO нет критериев проверки на валидность
            const clickable = !(isExpired || isWarning || isNotValid)
            if(clickable) {
                setSelectedCertificate(certificates[0])
            }
        }
    }, [certificates])

    if (certificates === null) {
        return <span>Поиск сертификатов...</span>
    }

    if (certificates?.length === 0) {
        return (
            <Alert showIcon description="Не найдено ни одного сертификата подписи" type="warning" />
        )
    }

    if(showTransactionDetails) {
        return  <TransactionDetails selectedCertificate={selectedCertificate}/>
    }

    return (
        <>
            {certificates.map((item) => (
                <UserCertificateItem
                    key={item.uuid}
                    certificate={item}
                    isActive={item.uuid === selectedCertificate?.uuid}
                    onClick={() => setSelectedCertificate(item)}
                />
            ))}
        </>
    )
}
