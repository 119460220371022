import { Badge, Box } from '@dltru/dfa-ui'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import style from '../style.m.less'

interface ISidebarLink {
    to: string
    onClick?: () => void
    locationState?: unknown
    count?: number
}

export const SidebarLink: FC<ISidebarLink> = ({ children, to, count, locationState, onClick }) => {
    if (onClick) {
        return (
            <Box direction="row" justify="space-between" align="center">
                <div style={{ width: '137px', lineHeight: '22px' }}>
                    <a
                        className={style.sidebarLink}
                        href="/"
                        onClick={(e) => {
                            e.preventDefault()
                            onClick()
                        }}
                    >
                        {children}
                    </a>
                </div>
                {count && (
                    <Badge
                        style={{ background: 'rgb(0 126 242 / 50%)' }}
                        count={count}
                        size="default"
                    />
                )}
            </Box>
        )
    }
    return (
        <Box direction="row" justify="space-between" align="center">
            <div style={{ width: '137px', lineHeight: '22px' }}>
                <Link className={style.sidebarLink} to={to} state={locationState}>
                    {children}
                </Link>
            </div>
            {count && (
                <Badge
                    style={{ background: 'rgb(0 126 242 / 50%)' }}
                    count={count}
                    size="default"
                />
            )}
        </Box>
    )
}
