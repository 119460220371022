import { Box } from '@dltru/dfa-ui'
import classnames from 'classnames/bind'
import { FC } from 'react'

import style from './style.m.less'

const cn = classnames.bind(style)

export const AuthSideBox: FC = () => {
    return (
        <Box className={style.authsidebox} margin={[0, 10, 0, 0]} padding={30}>
            <Box width={'124px'} className={cn(style.title)}>
                ИНВЕСТОРАМ И ЭМИТЕНТАМ
            </Box>
        </Box>
    )
}
